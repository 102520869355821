<template>
  <div>
    <b-card-code title="Add Condition" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="6">
              <b-form-group label="Name (English)" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="nameen"
                    v-model="data.nameList.en"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Description (English)"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-input
                    id="translationDescriptionen"
                    v-model="data.descriptionList.en"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Name (Arabic)" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="namear"
                    v-model="data.nameList.ar"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Description (Arabic)"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-input
                    id="translationDescriptionar"
                    v-model="data.descriptionList.ar"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- From -->
            <b-col md="6">
              <b-form-group label="From" label-for="From">
                <validation-provider
                  #default="{ errors }"
                  name="From"
                  rules="required|regex:^[0]*[1-9][0-9]*$"
                >
                  <b-form-input
                    id="From"
                    min="1"
                    type="number"
                    v-model="data.from"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- From -->
            <b-col md="6">
              <b-form-group label="To" label-for="To">
                <validation-provider
                  #default="{ errors }"
                  name="To"
                  rules="required|regex:^[0]*[1-9][0-9]*$"
                >
                  <b-form-input
                    id="To"
                    min="1"
                    type="number"
                    v-model="data.to"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>

import BCardCode from '@core/components/b-card-code'

import { heightTransition } from '@core/mixins/ui/transition'

import { required } from '@validations'
export default {
  components: {

    BCardCode,
  },

  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      id: 0,
      data: {
        from: '',
        to: '',
        nameList: {
          en: '',
          ar: '',
        },
        descriptionList: {
          en: '',
          ar: '',
        },
      },
      errors_back: [],
      required,
    }
  },
  created() {
    // request caategoru data
    this.id = this.$route.params.id
    axios
      .get('conditions/' + this.id)
      .then((result) => {
        const data = result.data.data
        //console.log(data)
        this.data.from = data.from
        this.data.to = data.to
        this.data.nameList.en = data.name.en
        this.data.nameList.ar = data.name.ar
        this.data.descriptionList.en = data.description.en
        this.data.descriptionList.ar = data.description.ar
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    // update function to category
    // submit Condition API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .put('conditions/' + this.id, this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/Vendor/ConditionIndex')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
